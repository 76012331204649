import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import ReactMarkdown from 'react-markdown'
import { Helmet } from "react-helmet";

const BlogPost = () => {
  const { id } = useParams();
  const [individualBlog, setIndividualBlog] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      const requestOptions = {
        method: 'GET',
        redirect: 'follow'
      };
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/cms/api/blogs?filters[slug][$eq]=${id}&populate=*`, requestOptions);
        const result = await response.json();
        setIndividualBlog(result?.data[0]);
      } catch (error) {
        console.log('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);


  return (
    <div className="bgblack ">
      <Helmet>
        <meta name="title" content={`${individualBlog.attributes?.defaultSeo === null ? '' : individualBlog?.attributes?.defaultSeo?.title}`} />
        <meta charSet="utf-8" name="description" content={`${individualBlog.attributes?.defaultSeo === null ? '' : individualBlog?.attributes?.defaultSeo?.description}`}/>
        <title>{`${individualBlog.attributes?.defaultSeo === null ? '' : individualBlog?.attributes?.defaultSeo?.title}`}</title>
        <link rel="cononical" href={`https://www.instashield.org/${individualBlog?.attributes?.slug}`} />
      </Helmet>
      <div className="container padding_btm">
        <div className="d-flex flex-column justify-content-center align-items-center ">
          <div className="col-lg-10 col-12 mt-5  p-lg-5  p-2 text-center ">
            <h5 className="textgrey mt-lg-4 mt-4  ">{individualBlog?.attributes?.pdate}</h5>
            <h1 className="textgrey headingfont mt-3">
              {individualBlog?.attributes?.Title}
            </h1>

          </div>
        </div>

        <div className="mt-5 position-relative">
          <img src={`${process.env.REACT_APP_API_URL}/cms${individualBlog?.attributes?.image?.data?.attributes?.url}`} className="img-fluid w-100" />


        </div>
        <div className="textgrey mt-4" >
          <ReactMarkdown children={individualBlog?.attributes?.Content} />
          {/* <p dangerouslySetInnerHTML={{ __html: individualBlog?.attributes?.Content }} className="blog_font" >
           
          </p> */}

        </div>

        {/* <div className="container mt-5 ">
            <h1 className="textgrey">Related Storeis</h1>
          <div className=" d-flex flex-lg-row flex-md-row flex-column row  mt-lg-5 mt-4   gap-md-0 gap-3">
            <div className="col-lg-3 col-12 col-md-6 p-lg-2 p-md-2">
              <img src="/images/s-l960_720.png" className="img-fluid "></img>
              <div className="p-lg-4 p-4 bg-white">
                <p className="textlightgray m-0 mt-lg-4">November 23, 2022</p>
                <p className="textlightgray mt-1 textlightgray">
                  Benefits Of Electron Technology
                </p>
                <p className="textlightgray mt-lg-4 textlightgray ">
                  The modern world, full of pollution and electronic devices, is
                  a positive ion prison. Since everything in{" "}
                </p>
                <div className="righticon">
                  <i className="fa-solid fa-arrow-right-long fs-3 "></i>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-12 col-md-6 p-lg-2 p-md-2">
              <img src="/images/s-l960_720.png" className="img-fluid "></img>
              <div className="p-lg-4 p-4 bg-white">
                <p className="textlightgray m-0 mt-lg-4">November 23, 2022</p>
                <p className="textlightgray mt-1 textlightgray">
                  Benefits Of Electron Technology
                </p>
                <p className="textlightgray mt-lg-4 textlightgray ">
                  The modern world, full of pollution and electronic devices, is
                  a positive ion prison. Since everything in{" "}
                </p>
                <div className="righticon">
                  <i className="fa-solid fa-arrow-right-long fs-3 "></i>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-12 col-md-6 p-lg-2 p-md-2 ">
              <img src="/images/s-l960_720.png" className="img-fluid "></img>
              <div className="p-lg-4 p-4 bg-white">
                <p className="textlightgray m-0 mt-lg-4">November 23, 2022</p>
                <p className="textlightgray mt-1 textlightgray">
                  Benefits Of Electron Technology
                </p>
                <p className="textlightgray mt-lg-4 textlightgray ">
                  The modern world, full of pollution and electronic devices, is
                  a positive ion prison. Since everything in{" "}
                </p>
                <div className="righticon">
                  <i className="fa-solid fa-arrow-right-long fs-3 "></i>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-12 col-md-6 p-lg-2 p-md-2">
              <img src="/images/s-l960_720.png" className="img-fluid "></img>
              <div className="p-lg-4 p-4 bg-white">
                <p className="textlightgray m-0 mt-lg-4">November 23, 2022</p>
                <p className="textlightgray mt-1 textlightgray">
                  Benefits Of Electron Technology
                </p>
                <p className="textlightgray mt-lg-4 textlightgray ">
                  The modern world, full of pollution and electronic devices, is
                  a positive ion prison. Since everything in{" "}
                </p>
                <div className="righticon">
                  <i className="fa-solid fa-arrow-right-long fs-3 "></i>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default BlogPost;
