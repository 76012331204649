import React, { useContext, useState } from "react";
import { Auth } from "../AuthContext/Authcontext";
import { Helmet } from "react-helmet";
const Enquiry = () => {
  const { notify } = useContext(Auth)
  const [IsValid, setIsValid] = useState(false);

  const [apidata, setApiData] = useState({
    fullname: "",
    phone: "",
    email: "",
    locDealership: "",
  });
  const handleChange = (event) => {
    // console.log("changing",event.target.value)
    setApiData({ ...apidata, [event.target.name]: event.target.value });
  };
  const handleSubmit = (e) => {
    e.preventDefault()
    let data = JSON.stringify({
      data: {
        fullname: apidata.fullname,
        subject:null,
        message: null,
        email: apidata.email,
        PhoneNo: apidata.phone,
        LocationName:apidata.locDealership,
      },
    });
   
    let url = `${process.env.REACT_APP_API_URL}/cms/api/dealership-enquiries`;

    let headers = {
      "Content-Type": "application/json",
    };

    let options = {
      method: "POST",
      headers: headers,
      body: data,
    };

    fetch(url, options)
      .then((response) => {
        if (response.status === 200) {
          notify("Thankyou for Contacting us ,we will get back to you soon!")
          setApiData({
            ...{
              fullname: "",
              email: "",
              phone: "",
              locDealership: "",
            }
          })
        }
      }
      )
      // .then((data) => console.log(data,"check"))
      .catch((error) => console.log(error));
  };
  const handleMobileNumberValidation = (value) => {
    let mobileNumberPattern = /^\d{10}$/;
    if (mobileNumberPattern.test(value)) {
      setIsValid(false);
      setApiData({ ...apidata, phone: value });
      return true;
    } else {
      setIsValid(true);
      return false;
    }
  };
  return (
    <div className="">
      <Helmet>
        <meta name="title" content="Inquire for Tailored Wellness Solutions | Your Path to a Healthier Life Begins Here" />
        <meta charSet="utf-8" name="description" content="Explore personalized wellness solutions tailored to your unique needs. Submit your
inquiry today and embark on a journey towards a healthier, more fulfilling life. Discover the
revolutionary technology that can transform your well-being. Take the first step – enquire now.&quot;"/>
        <title>Inquire for Tailored Wellness Solutions | Your Path to a Healthier Life Begins Here</title>
        <link rel="cononical" href="https://www.instashield.org/enquiry" />
      </Helmet>


      <div className=" d-flex flex-lg-row flex-column ">
        <div className="col-lg-4 col-12 position-relative bgblack enquiry_img" style={{ zIndex: "-1" }} >
          <img src="images/Dealership.jpg" className="img-fluid " />
          {/* <div className="overlay"></div> */}

          {/* <div className="position-absolute  enquiry_logo">
            <img src="/images/LOGO.png" width={120} />
          </div> */}

          {/* <div className="position-absolute custom_bottom w-100  p-lg-4 p-0 ">
            <div className="text-center">
              <div className=" col-lg-12   fontsize12  text-white p-lg-0 p-1">
                <div className="col-lg-12 col-6 text-center">
                  <p className="m-0">Call us today </p>
                  <p className=""> 18002028308</p>
                  
                </div>
                <div className="col-lg-12 col-6 text-center ">
                  <p className="m-0">Send an Emails </p>
                  <p className=""><a href="" className="text-white text-decoration-none ">info@instashield.org </a></p>
                  
                </div>
                
            </div>
            <div className="col-lg-12 col-12 text-center fontsize12 text-white p-lg-0 p-1">
                  <p className="m-0">Visit our HQ </p>
                  <p>904, Meraki Arena, V.N. Purav Marg, Opp R.K. Studio, Above Croma Showroom, Chembur (East), Mumbai- 400071</p>
                  
                </div>
              </div>
          </div> */}
        </div>

        <div className="col-lg-8 col-12 p-lg-5 p-2 mt-lg-0 mt-5">
          <div className="p-lg-5 p-4 mx-lg-4">
            <h3>Send us a message for dealership Enquiry</h3>
            <p className="textgrey">
              Contact us today using this form and our support team will reach
              out as soon as possible.
            </p>

            <form className="mt-lg-4" onSubmit={(e) => handleSubmit(e)}>
              <div class="form__group field">
                <input
                  type="input"
                  class="form__field"
                  placeholder="First name"
                  name="fullname"
                  id="name"
                  required
                  onChange={handleChange}
                  value={apidata.fullname}
                />
                <label for="name" class="form__label">
                  Full Name
                </label>
              </div>
              <div class="form__group field">
                <input
                  type="text"
                  class="form__field"
                  placeholder="Enter Email"
                  name="phone"
                  id="phone"
                  required
                  onChange={(e) =>
                    handleMobileNumberValidation(e.target.value)
                  }
                />
                <label for="Phone No" class="form__label">
                  Phone No.
                </label>
                {IsValid ? (
                  <p className="text-danger text-xs italic mt-2 m-0">
                    Enter a Valid 10 digit Mobile Number.
                  </p>
                ) : (
                  ""
                )}
              </div>
              <div class="form__group field">
                <input
                  type="email"
                  class="form__field"
                  placeholder="Enter Subject"
                  name="email"
                  id="email"
                  required
                  onChange={handleChange}
                  value={apidata.email}
                />
                <label for="subject" class="form__label">
                  Email
                </label>
              </div>
              <div class="form__group field">
                <input
                  type="input"
                  class="form__field"
                  placeholder="Location for Dealership"
                  name="locDealership"
                  id="message"
                  required
                  onChange={handleChange}
                  value={apidata.locDealership}
                />
                <label for="message" class="form__label">
                  Location for Dealership
                </label>
              </div>
              <div class=" mt-5 mx-lg-0 mx-5">
                {" "}
                <button disabled={ !IsValid  ? false : true} class="bgblack  text-white  submit_btn border-0">
                  Submit Form
                </button>
              </div>
            </form>

            <div className="mt-3 ">
              <p className="m-0 fontsize14">For Customer Enquiries</p>
              <div className="d-flex gap-4 fontsize14">
                <p className="m-0   text-black">Call us at: <a href="tel: 18002028308" className=" textblack text-decoration-none "> 18002028308 (8:00 AM to 8:00 PM, 365 days)</a> </p>
                <p className="m-0   ">Email us  at: <a href="mailto: info@instashield.org" className="textblack text-decoration-none ">info@instashield.org</a> </p>
              </div>    </div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default Enquiry;
