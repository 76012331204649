import React, { useContext, useEffect } from "react";
import "./index.css";
import EyeGrirl from "./EyeGrirl";
import Application from "./Application";
import ProductSpecification from "./ProductSpecification";
import RevolutionaryTech from "./RevolutionaryTech";
import ProductInfo from "./ProductInfo";
import { Auth } from "../../Components/AuthContext/Authcontext";
import { Helmet } from "react-helmet";
// import myImage from '/productimage/safe.png';
// import exampleImage from './productimage/product.png'
function Product(props) {
  const { getProduct, AddedProduct } = useContext(Auth);
  useEffect(()=>{
    getProduct()
    AddedProduct()
  },[])
  return (
    <div>
      <Helmet>
      <meta name="title" content="Health Care Technology Products | Environment Virus Free - Instashield" />
        <meta charSet="utf-8" name="description" content="Instashield healthcare wellness device, that disable all kind of air borne viruses and
         fungi that helps keep your environment sterile and regulate sleep patterns. Buy Online!"/>
        <title>Health Care Technology Products | Environment Virus Free - Instashield</title>
        <link rel="cononical" href="https://www.instashield.org/product" />
      </Helmet>

   
    <div className="fade-in ">
      <ProductInfo />
      <EyeGrirl />
      <Application />
      <ProductSpecification />
      <RevolutionaryTech />
    </div>
    </div>
  );
}

export default Product;
