import React from "react";

const Certificates = () => {
  return (
    <div className="py-5  bgblack">
      <div className=" container  px-5">
        <h1 className="text-white text-center certificate_heading mt-lg-3 mb-lg-5">Certificates</h1>
        <div className="d-flex justify-content-center flex-wrap gap-5 mt-lg-4 mt-3">
          <div className="col-lg-3 col-12">
            <img
              src="/images/certificate2.jpg"
              alt="certificate"
              className="certificate"
            />
          </div>

          <div className="col-lg-3 col-12">
            <img
              src="/images/certificate4.jpg"
              alt="certificate"
              className="certificate"
            />
          </div>

          <div className="col-lg-3 col-12">
            <img
              src="/images/certificate5.jpg"
              alt="certificate"
              className="certificate"
            />
          </div>
          <div className="col-lg-3 col-12">
            <img
              src="/images/certificate6.jpg"
              alt="certificate"
              className="certificate"
            />
          </div>
          <div className="col-lg-3 col-12">
            <img
              src="/images/certificate3.jpg"
              alt="certificate"
              className="certificate"
            />
          </div>
          

          <div className="col-lg-3 col-12">
            <img
              src="/images/certificate1.jpg"
              alt="certificate"
              className="certificate"
            />
          </div>
          <div className="col-lg-3 col-12">
            <img
              src="/images/certificate7.jpg"
              alt="certificate"
              className="certificate"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Certificates;
