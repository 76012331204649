import React from "react";
import "./index.css";

function Application() {
  return (
    <>
      <div className=" main-container">
        {/* <p className="text-secondary"> Application Of</p> <b>Instashield</b> */}

        <p className="d-flex fs-5 application-text justify-content-center gap-2 ">
          <h3 className="text-secondary"> Applications of </h3>
          <h3 className="text-white ">Instashield</h3>
        </p>
     
<div className="p-3 mt-lg-2">
        <div className="d-flex mt-5 flex-lg-row flex-column justify-content-center align-items-center ">
         
          <div className="col-md-2 col-6 col-sm-6 text-center">
            <img
              className="application-image zoom-in-zoom-out"
              src="/productimage/home-and-apparment.png"
            ></img>
            <p className="greytext mt-2">Homes and <br></br> Apartment</p>
          </div>
          <div className="col-md-2 col-6 col-sm-6 text-center">
            <img
              className="application-image zoom-in-zoom-out"
              src="/productimage/hotel.png"
            ></img>
            <p className="greytext  mt-2">Hotels and <br></br> Restaurants</p>
          </div>
          <div className="col-md-2 col-6 col-sm-6 text-center">
            <img
              className="application-image zoom-in-zoom-out"
              src="/productimage/hospital-and-clinic.png"
            ></img>
            <p className="greytext mt-2">Hospitals and <br></br> Clinics</p>
          </div>
          <div className="col-md-2 col-6 col-sm-6 text-center">
            <img
              className="application-image zoom-in-zoom-out"
              src="/productimage/mall.png"
            ></img>
            <p className="greytext mt-2 ">Malls and <br></br> Function Halls</p>
          </div>
         
        </div>
        <div className="d-flex flex-lg-row flex-column justify-content-center align-items-center mt-lg-4">
         
          <div className="col-md-2  col-6  col-sm-6 text-center">
            <img
              className="application-image-2 mx-lg-0 mx-1 zoom-in-zoom-out"
              src="/productimage/school-and-college.png"
            ></img>
            <p className="greytext mt-2">Schools and <br></br> Colleges</p>
          </div>
          <div className="col-md-2 col-6 col-sm-6 text-center">
            <img
              className="application-image-2 mx-lg-0 mx-1 zoom-in-zoom-out"
              src="/productimage/place-of-worship.png"
            ></img>
            <p className="greytext mt-2"> Places of <br></br> worship</p>
          </div>
          <div className="col-md-2 col-6 col-sm-6 text-center">
            <img
              className="application-image-2 mx-lg-0 mx-1 zoom-in-zoom-out"
              src="/productimage/office.png"
            ></img>
            <p className="greytext mt-2">Government <br></br> Institutions</p>
          </div>
         
        </div>
      </div>
      </div>
    </>
  );
}

export default Application;
