import React from "react";
import { Helmet } from "react-helmet";

export const Refund = () => {
  return (
    <div className="bgblack m-0">
       <Helmet>
       <meta name="title" content="Cancellation and Refund Policy: Your Guide to Hassle-Free Transactions" />
        <meta
          charSet="utf-8"
          name="description"
          content="Understand our transparent Cancellation and Refund Policy to ensure a seamless
          experience. Explore the details of our commitment to customer satisfaction, providing clarity on
          cancellation procedures and refund processes. We value your trust and strive to make every
          transaction worry-free. Review our policy now for a confident and informed purchase."
        />
        <title>
        Cancellation and Refund Policy: Your Guide to Hassle-Free Transactions
        </title>
        <link rel="cononical" href="https://www.instashield.org/refund" />
      </Helmet>
      <div className="container  py-5 textgrey ">
        <div className="px-lg-4 mx-lg-1 mx-0">
          <h1 className="m-0 px-lg-2 mx-lg-2  mt-lg-4 mt-2 ">Cancellation and Refund</h1>
        </div>
        <div className="px-lg-5 py-5">
          <h3 className="text-white mb-lg-3">Shipping of Products</h3>
          <p>
            Orders placed by You on the Platform are typically processed within
            2 (two) business days from the date of purchase. The Products
            ordered will be delivered to You within 2 (two) – 5 (five) business
            days. At times when we are experiencing high volume of orders,
            shipments may get delayed by a few days, in which case, You will be
            intimated by email or telephone call (on the details shared by You
            at the time of placing Your order/registering on the Platform).
          </p>
          <p>
            Once Your order is dispatched, You will receive an e-mail or SMS
            updating You with the details of the time and progress of the
            delivery. On the date of delivery, the person delivering the Product
            will reach out to You (on the number shared by You at the time of
            placing Your order/registering on the Platform) to confirm Your
            presence to receive the Order.
          </p>
          <p>
            You are requested to be available on the date of delivery since the
            Products will either be hand delivered to You or to someone
            appointed by You to collect the order on Your behalf.
          </p>
          <p>
            All deliveries sent to residential or commercial spaces require a
            receipt signature for authentication. We take no responsibility for
            shipment receipt signed by an alternative person other than the
            person ordering the Product or someone appointed by You to receive
            the order at the delivery address. Maximum of 2 (two) attempts will
            be made on the date of delivery to deliver the Product at the
            address indicated as the delivery point, at the time of placing the
            order post which the order will be cancelled, and refund will not be
            given to You.
          </p>
          <p>
            We do not take responsibility for an incorrect address that was
            provided at the time of placing the order. In case of an incorrect
            address, the product will be returned by the rider and refund will
            not be given to You.
          </p>
          <p>
            All claims for damages must be reported to customer service through
            an email at: info@instashiel.org within 24 (twenty four) hours of
            order delivery.
          </p>
          <h3 className="text-white mt-lg-5 mt-3 mb-lg-3" > Cancellation, and Exchange</h3>
          <p>
            An order placed and confirmed cannot be cancelled until the Products
            have been delivered to You. You may deny or refuse to take delivery
            of the Products, upon which the order will be cancelled, and refund
            will be given to You.
          </p>
          <p>
            You are entitled to a 100% replacement of order in case You received
            something else than what You ordered or Your order reached You in a
            distorted manner. If purchase of the Product is made under a special
            promotional offer or any special scheme, special rules shall apply
            for replacement.
          </p>
          <p>
            Replacement process must be initiated within 24 (twenty four) hours
            from the time of receipt of order by the User.
          </p>
          <p>
            The product must be in an unused state before seeking for a
            replacement and should be accompanied by a copy of the original
            receipt or invoice issued at the time of sale of the Product.
          </p>
          <p>
            Any request for replacement will be accepted only after inspection
            of the Product. Once the replacement request is placed with the
            customer service, the logistics partner will arrange for a pickup of
            the Product from the address provided at the time of placing the
            order within 3 (three) working days. Once the Product is received,
            it takes about 1 (one) working day for inspection team to confirm on
            the replacement. On confirmation of the genuine need for replacement
            of the Product, we shall replace the desired Product in 5 (five)
            working days.
          </p>
          <p>
            At every step of the replacement process, we shall keep You updated
            through emails and/or SMS. 100% refund will be initiated on
            cancelation, minus shipping charges.
          </p>
        </div>
      </div>
    </div>
  );
};
