import React from "react";
import Marquee from "react-fast-marquee";

const Media = () => {
  const Images = [
    {img: "images/media_1.png", },
    { img: "images/media_2.png"},
    { img: "images/media_3.png"},
    { img: "images/media_4.png"},
    { img: "images/media_5.png"},
    { img: "images/media_6.png"},
    { img: "images/media_7.png"},
    { img: "images/media_8.png"},
    { img: "images/media_9.png"},
    { img: "images/media_10.png"},
  ];

  // style={{paddingBottom:"5px"}}
  return (
    <div className="bgblack ">
    <div className=" bg1 ">
      <div className="d-flex flex-column justify-content-center align-items-center p-5">
        <div className="d-flex gap-2 mt-lg-5">
          <h1 className="textgrey">In</h1> <h1 className="text-white">Media</h1>{" "}
        </div>

    
     
       <div className="d-lg-block d-none"> <Marquee className="" loop={10}>
           <div className="d-flex container justify-content-between align-items-center gap-2 gap-lg-5 mt-5 p-lg-5 bg1">
          {Images.map((item, index) => {
            return (
            <div className="col-lg-3 col-md-4 col-12 d-flex justify-content-center" key={index}>
              <img src={item.img} className="img-fluid" alt={`Image ${index + 1}`}   />
            </div>)
          })}  
        </div></Marquee> 
        </div>
     
       <div className="d-lg-none d-md-none d-block"> <marquee  loop > 
         <div className="d-flex  justify-content-between align-items-center gap-4  mt-5 p-3 bg1">
         {Images.map((item, index) => (
            <div className="col-12 d-flex justify-content-center align-items-center" key={index}>
              <img src={item.img} className="img-fluid" alt={`Image ${index + 1}`}   />
            </div>
          ))} 
        </div></marquee> </div>
   
      </div>
    </div>
    </div>
  );
};

export default Media;
