import React from "react";
import "./index.css";

function EyeGrirl(props) {
  return (
    <>
      <div className=" main-container">
        <div className="d-flex flex-lg-row flex-column">
          <div className="col-lg-7  p-0">
            <img
              className="img-fluid "
              src="/productimage/eye-girl.png"
            ></img>
          </div>
          <div  className="col-lg-5   bg-eye justify-content-center eye-text ">
            <div className="mt-lg-4 p-lg-4">
            <p  className="txt-color ">
              Ready to embark on a journey that brings you at the forefront of{" "}
              <b>holistic living?</b>
            </p>
            <p  className="txt-color ">
              Your ultimate wellness companion, offering complete safety without
              any harmful side effects.{" "}<br></br>
              <b>Experience this breakthrough technology -</b> Bring home
              Instashield today.
            </p>
              
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EyeGrirl;
