import React, { useContext } from 'react';
import { useNavigate } from 'react-router';
import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { Auth } from '../AuthContext/Authcontext';
function Thankyou() {
    const [checkoutResponse, setCheckoutResponse] = useState(null)
    console.log(checkoutResponse)
    const { ApplyGST, gstValidated, setGst, setAddedCart, setGstValidated } = useContext(Auth)
    const navigate = useNavigate()
    const Completecheckout = async () => {
        const cartid = localStorage.getItem('cartid_instashield')
        var myHeaders = new Headers();
        var requestOptions = {
            headers: myHeaders,
            method: 'POST',
            redirect: 'follow'
        };
        await fetch(`${process.env.REACT_APP_API_URL}/store/carts/${cartid}/complete`, requestOptions)
            .then(response => response.json())
            .then(result => {
                setCheckoutResponse(result)
                if (result.data.id){
                    localStorage.removeItem('cartid_instashield')
                    setAddedCart('')
                    if (gstValidated){
                        ApplyGST(result.data.id)
                    }
                    setGstValidated(false)
                    setGst('')

                }
            })
            .catch(error => console.log('error', error));
    }
    useEffect(() => {
        const cart_id = localStorage.getItem("cartid_instashield")
        if (cart_id) {
            Completecheckout()
        } else {
            navigate('/404', { scroll: false })
        }
    }, [])
    
    return (
        <div class="d-flex align-items-center justify-content-center vh100 bgblack text-white">
            <div class="text-center">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="75"
                    height="75"
                    fill="currentColor"
                    className="bi bi-check-circle-fill textgreen mb-3"
                    viewBox="0 0 16 16"
                >
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                </svg>
                {
                    checkoutResponse?.data?.object === 'order' ? <h1 class="display-1 fw-bold">Thank you</h1> :
                        <>
                            {
                                checkoutResponse === null ? <div>
                                    <span className="h4 mr-3 mx-3">Please Wait</span>
                                    
                                    <Spinner className='mt-1' size={28} animation="border" variant="primary" />
                                </div> : ""
                            }
                        </>
                }
                {
                    checkoutResponse?.data?.object === 'order' ? <p class="fs-3"> Your Order has been place sucessfully</p>
                        :
                        <>
                            {
                                checkoutResponse === null ? <p class="fs-3"> We are fetching Your details</p> : ""}

                        </>

                }
                {
                    checkoutResponse?.message ?
                        <p>{checkoutResponse.message}</p> : ""
                }
                {
                    checkoutResponse?.data?.object === 'order' ?

                        <div className='d-flex text-center align-items-center justify-content-center '>
                            <p className="">Order ID :- </p>
                            <p style={{ fontSize: '20px' }} className=" text_green mx-2 mb-3 ">               
                              {checkoutResponse.data.id}
                            </p>

                        </div>
                        : ""
                }

                <div className=" col-lg-11 d-flex justify-content-between align-items-center mt-4">
                    <p className="textblue m-0">
                        Having trouble?{" "}
                        <a href="/enquiry" className="text-decoration-none">
                            {" "}
                            Contact us{" "}
                        </a>
                    </p>
                    <a href="/" class="btn bg_green text-white">
                        Go Home
                    </a>
                </div>
            </div>
        </div>
    );
}

export default Thankyou;