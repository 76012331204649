import React, { useState } from "react";
import { Helmet } from "react-helmet";

const Faq = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleAccordionClick = (index) => {
    setActiveIndex((prev) => (index == prev ? null : index));
  };
  const FaqData = [
    {
      ques: "Does Instashield work like Air Purifier? ",
      answer:
        "Instashield does not work like an air purifier which requires filters to clean the air. This anti-depressant machine emits negative ions to eliminate all airborne allergens and pollen, including the SARS-CoV2 virus. ",
    },
    {
      ques: "Is Instashield Safe for Home & Offices? ",
      answer:
        "Instashield is a safe, effective and convenient device to stay protected from all airborne diseases and manage stress and anxiety at work. It produces thousands of negative ions in an enclosed space to kill microbes, viruses and bacteria and can be used anywhere in your home or office. ",
    },
    {
      ques: "How does it protect from viruses? ",
      answer:
        "Instashield is a virus slayer electronic device that works on the principle of negative ions generation technology to kill viruses in the air or surface in an enclosed space. This portable antivirus device can protect from all kinds of viruses for people at home or work, places where traditional cleaning methods fail. Its portable and compact design makes it easy to carry with you anywhere. ",
    },
    {
      ques: "Is Instashield can kill coronavirus and its variants? ",
      answer:
        "Yes, Instashield can kill coronavirus and all its variants with up to 99.9% efficiency in the air and on surfaces at home or office. The Instashield corona killer emits negative ions that inactivate all the viruses and keep them away from your face and body. ",
    },
    {
      ques: "How Does It Manage Stress and Anxiety at Work? ",
      answer:
        "In today’s fast-paced life, we all have to deal with constant stress and anxiety to some extent. This can be caused by certain situations at work and in your personal life. Fortunately, we have Instashield that can help you to reduce stress and anxiety by increasing the concentration of negative ions in your enclosed space. ",
    },
    {
      ques: "How Does It Reduce Tension and Anxiety? ",
      answer:
        "Negative ions released by Instashield can help to reduce tension and anxiety levels in your body and enhance your mood, increasing energy levels and productivity. Moreover, these negative ions not only help you relax but also enhance your overall health. ",
    },
    {
      ques: "How Instashield helps with sleep and relaxation? ",
      answer:
        "The negative ions created by Instashield will help with anxiety relief, mood enhancement, getting sleep and relaxation and more. So, if you are looking for the best anxiety relief products to get relaxation and reduce anxiety, this anti-depression machine can surely help. ",
    },
    {
      ques: "How Instashield reduce depression and anxiety? ",
      answer:
        "Instashield, an electron-based plug & play device, emits negative ions that can boost the production of serotonin in your brain. Serotonin is a neurotransmitter responsible for reducing depression and anxiety and regulating moods and emotions. ",
    },
    {
      ques: "How does Instashield take care of our mental health? ",
      answer:
        "Instashield is a negative ion generator that is highly effective in creating a calm and stress-free environment at home or the workplace. Using this negative ion producer, you can not only make the air inside your home or office clean and breathable but also help you de-stress, elevate your mood & energy levels, and lead to overall mental well-being. In addition, this electronic device is highly reliable and lasts longer than other models. ",
    },
  ];
  return (
    <div className="bgblack justify-content-center align-items-center d-flex flex-lg-column flex-column p-lg-5 p-1">
      <Helmet>
      <meta name="title" content="Get Answers to Your Questions: Explore Our FAQ Page - Instashield" />
        <meta
          charSet="utf-8"
          name="description"
          content="Find clarity and insight on our Frequently Asked Questions (FAQ) page. Discover
comprehensive answers to common queries about our wellness solutions and Instashield products.
Empower yourself with knowledge as you explore our FAQ section. Ready to learn more? Dive into
the details now."
        />
        <title>
          Get Answers to Your Questions: Explore Our FAQ Page - Instashield
        </title>
        <link rel="cononical" href="https://www.instashield.org/faq" />
      </Helmet>

      <div className="d-flex flex-column justify-content-center align-items-center position-relative mt-lg-0 mt-5">
        <img src="/images/faq.svg" className="questionimg" />

        <h3 className="text-white">Frequnetly Asked Question's</h3>
      </div>
      <div className="col-lg-9 col-12 custom-accordion mt-4 p-1 mb-5">
        {FaqData.map((item, index) => {
          const accordionId = `accordion-${index}`;
          // const collapseId = `collapse-${index}`;
          // const isActive = activeIndex === index;

          return (
            <div
              className={`accordion-item mt-1 mb-3 p-lg-3 p-2 ${
                activeIndex === index ? "" : ""
              }`}
              key={index}
            >
              <div className="accordion-header" id={index}>
                <button
                  className="accordion-button"
                  type="button"
                  onClick={() => {
                    handleAccordionClick(index);
                  }}
                >
                  <div className="m-0 textcolor">{item.ques}</div>
                </button>
              </div>
              <div
                id={index}
                className={`accordion-collapse ${
                  activeIndex == index ? "" : "show"
                }`}
                aria-labelledby={accordionId}
              >
                <div className="accordion-body">
                  <p className="m-0 textgrey">{item.answer}</p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Faq;
