import React, { useRef } from "react";
import { Helmet } from "react-helmet";

const Contact = () => {
  
  return (
    <div className="bgblack py-5 vh-100  d-flex" >
 <Helmet>
 <meta name="title" content="Connect with Us: Reach Out on Our Contact Us Page - Instashield" />
        <meta
          charSet="utf-8"
          name="description"
          content="Have questions or inquiries? Contact us through our dedicated Contact Us page.
          Whether you&#39;re seeking more information about our wellness solutions or looking to collaborate,
          we&#39;re here to assist you. Reach out today and let&#39;s start a conversation. Your journey to well-being
          begins with a simple click."
        />
        <title>
        Connect with Us: Reach Out on Our Contact Us Page - Instashield
        </title>
        <link rel="cononical" href="https://www.instashield.org/contact" />
      </Helmet>


      <div className="textwhite_light d-flex flex-column justify-content-center text-center align-items-center container py-5   ">
        <h1 className="display-4">Ready to help you 24/7</h1>
        <div className="py-5 fs-5">
          <p className="m-0 py-1 text_hover  ">WhatsApp us: <a href = "tel: +91 924 732 8805" className="text_hover   text-decoration-none "> +91 924 732 8805</a> </p>
          <p className="m-0 py-1 text_hover ">Email at: <a href = "mailto: info@instashield.org" className="text_hover   text-decoration-none ">info@instashield.org</a> </p>
          <p className="m-0 py-1 text_hover ">Call on: <a href = "tel: 18002028308" className="text_hover   text-decoration-none "> 18002028308 (8:00 AM to 8:00 PM, 365 days)</a> </p>

        </div>
        <p className="pt-3 textgrey4 visitus fs-6">VISIT OR CONTACT HEAD OFFICE</p>
        <p className="textgrey4 m-0 fs-5 text_hover"><a href="tel: +91 924 732 8805" className="text_hover   text-decoration-none "> +91 924 732 8805</a></p>
        <p className="fs-6 mt-2">904, Meraki Arena, V.N. Purav Marg, Opp R.K. Studio, Above Croma Showroom,<br></br> Chembur (East), Mumbai- 400071</p>
      </div>
    </div>
  );
};

export default Contact;
