import React, { useEffect, useState } from "react";
import { FaRegSmile } from "react-icons/fa";
import { Link } from "react-router-dom";

export const Comprehensive = () => {
  const Benifits = [
    {
      title: "Ensures 99.99% Sterilized Environment",

    },
    {
      title: "Enhances Mental Wellbeing",
      
    },
    {
      title: "Revolutionary Wellness Technology",
      
    },
    {
      title: " Protects from all kinds of air borne viruses, bacteria and fungi",
      
    },
  ];
  const handleClick = () => {
    window.scroll(0, 0);
  };
  const [activeSmiley, setActiveSmiley] = useState(1);

  useEffect(() => {
    setTimeout(() => {
      setActiveSmiley(activeSmiley === Benifits.length ? 1 : activeSmiley + 1);
    }, [1500]);
  }, [activeSmiley]);

  return (
    <div className="comp_main" >
      <div className=" container-fluid d-flex flex-column text-lg-center align-items-center justify-content-center ">
        <div className=" px-lg-5 px-4">
          <h1 className="py-5 mt-5 textgrey">
            Comprehensive Spectrum Coverage Against <br />
            <span className="text-white">Virus, Bacteria,Fungi</span>
          </h1>
          <div>
            <p className="fontsize18 textgrey">
              Instashield is your overall safeguard against various potential
              pathogens such as viruses, bacteria, fungi among others.<br></br>
              The advanced wellness product protects and creates a sterile
              environment by carefully suppressing different pathogens.<br></br>
              It ensures wide spectrum coverage that results in a complete
              shield ensuring a<br></br> healthier and safe environment for the
              entire family.
            </p>
          </div>
        </div>

        <div className="py-2 px-2  position-relative d-none d-lg-block">
          <img
            src="/images/component1.png"
            alt="Benefits"
            className="py-5 w-75 img-fluid benefitsimg"
          />
          <div className="comp_benefits">
            <p className="m-0">BENEFITS OF</p>
            <h2>INSTASHIELD</h2>
          </div>
          <img
            src="/images/greycircle.svg"
            alt="greycirlce"
            className="greycircle"
          />
          <img src="/images/eclipse.svg" alt="eclipse" className="eclipse" />

          {Benifits.map((item, index) => {
            return (
              <div key={index}
                className={` d-flex justify-content-between  smiley${
                  index + 1
                } gap-2 ${activeSmiley === index + 1 ? "smiley-hover-effect" : ""} `}
              >
                <p className=" mt-1 textgrey" style={{padding:"7px"}}>{item.title}</p>
                <div className={`smiley${index + 1}img img-fluid`}>.</div>
              </div>
            );
          })}

          <div className="tech-ben-con1 d-flex flex-column align-items-start text-start">
            <h6 className="textgrey">
              Step into the future of health and unlock your full potential.
            </h6>
            <p>More info</p>
            <div className="d-flex  justify-content-center align-items-start">
              <Link to="/enquiry" onClick={handleClick}>
                
                <button className="benbtn1 py-lg-3 px-3 py-1">
                  Enquire Now
                </button>
              </Link>
              <Link to="/product" onClick={handleClick}>
                <button className="benbtn2 py-lg-3 py-1">BUY</button>
              </Link>
            </div>
          </div>
        </div>
        <div className="d-lg-none mobbenif position-relative text-center ">
          <img
            src="/images/component1.png"
            alt="Benefits"
            className="benefitmobimg  img-fluid"
          />
          <div className="compmobbene ">
            <p className="m-0">BENEFITS OF</p>
            <h2>INSTASHIELD</h2>
          </div>
          <img
            src="/images/greycircle.svg"
            alt="greycirlce"
            className="greycircle1"
          />
        </div>

        <div className="d-flex d-lg-none flex-column gap-0  justify-content-center  align-items-center text-center px-4 py-4 mb-lg-0 mb-5">
          <div className="d-flex flex-column align-items-center benitem ">
            <div className="mobsmiley1"></div>

            <p className="mt-2 px-2 py-2 fs-5">
              Ensures 99.99% Sterilized Environment
            </p>
          </div>
          <div className="d-flex flex-column align-items-center benitem ">
            <div className="vr verline"></div>
            <div className="mobsmiley1">.</div>

            <p className="mt-2 px-2 py-2 fs-5">Enhances Mental Wellbeing</p>
          </div>

          <div className="d-flex flex-column align-items-center benitem ">
            <div className="vr verline"></div>

            <div className="mobsmiley1">.</div>

            <p className="mt-2 px-2 py-2 fs-5">
              Revolutionary Wellness Technology
            </p>
          </div>
          <div className="d-flex flex-column align-items-center benitem">
            <div className="vr verline"></div>

            <div className="mobsmiley1">.</div>

            <p className="mt-2 px-2 py-2 fs-5">
              Protects from all kinds of air borne viruses, bacteria and fungi
            </p>
            {/* <div className="vr verline"></div> */}
          </div>
          <div>
            <h1 className="textgrey px-3 py-5 mt-2">
              Step into the future of health and unlock your full potential.
            </h1>
            <div className="d-flex flex-column">
              <Link to="/enquiry" onClick={handleClick}>
                <button className=" benmobbtn1  px-5 py-3">
                  Enquire <span className="textgrey">Now</span>
                </button>
              </Link>
              <Link to="/product" onClick={handleClick}>
                <button className="benmobbtn2 px-5 py-3">BUY</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
