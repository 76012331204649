import React from "react";
import { createContext, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import axios from "axios";

export const Auth = createContext();
function Authcontext({ children }) {

  const [productData, setProductData] = useState([]);
  const [addedCart, setAddedCart] = useState("");
  const notify = (value) => toast.success(value);
  const notifyError = (value) => toast.error(value);
  const [errorMessageForPincode, setErrorMessageForPincode] = useState(false);
  const [coupenResponse, setCoupenResponse] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [getUserLocationFailed, setGetUserLocationFailed] = useState("");
  const navigate = useNavigate();
  const [loadingProduct, setLoadingProduct] = useState(false);
  const [loadingPayment, setLoadingPayment] = useState(false);
  const [localShipping, setLocalShipping] = useState(0);
  const [gst, setGst] = useState('')
  const [gstValidated,setGstValidated]=useState(false)

  const [createAddress, setCreateAddress] = useState({
    first_name: "",
    last_name: "",
    address_1: "",
    address_2: "",
    city: "",
    region: "",
    country_code: "",
    postal_code: "",
    phone: "",
    email: "",
  });
  const getProduct = () => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };
    fetch(`${process.env.REACT_APP_API_URL}/store/products`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setProductData(result?.products);
      })
      .catch((error) => {
        if (error) {
          console.log("alert");
        }
      });
  };
  const getStateAndCity = (getStateAndCity) => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      `https://api.postalpincode.in/pincode/${getStateAndCity}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result[0].Status === "Success") {
          setCreateAddress({
            ...createAddress,
            city: result[0].PostOffice[0].Block,
            postal_code: getStateAndCity,
          });
          setErrorMessageForPincode(false);
        } else {
          setCreateAddress({
            ...createAddress,
            city: "",
          });
          setErrorMessageForPincode(true);
        }
      })
      .catch((error) => console.log("error", error));
  };
  const getShippingOption = (region_id) => {
    const cart_id = localStorage.getItem("cartid_instashield");
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(
      `${process.env.REACT_APP_API_URL}/store/shipping-options/${cart_id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => setLocalShipping(result.shipping_options[0]))
      .catch((error) => console.log("error", error));
  };

  const AddedProduct = () => {
    const cart_id = localStorage.getItem("cartid_instashield");
    var myHeaders = new Headers();

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(
      `${process.env.REACT_APP_API_URL}/store/carts/${cart_id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setAddedCart(result);
      })
      .catch((error) => console.log("error", error));
  };
  const Mocktest = () => {
    console.log("mock t-est");
    return window.open("thankyou");
  };
  const AddToCart = async (variant_id, qty, region_id) => {
    setLoadingProduct(true);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    var raw = JSON.stringify({
      region_id: region_id,
      items: [
        {
          variant_id: variant_id,
          quantity: qty,
        },
      ],
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(`${process.env.REACT_APP_API_URL}/store/carts`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result) {
          if (result.cart.id) {
            setAddedCart(result);
            localStorage.setItem("cartid_instashield", result.cart.id);
            setLoadingProduct(false);
            notify("Product Added Successfully!");
          }
        }
      })
      .catch((error) => {
        if (error) {
          setLoadingProduct(false);
          notify("Something Went Wrong!");
        }
      });
  };

  const UpdateQuantity = async (lineId, qty) => {
    const cart_id = localStorage.getItem("cartid_instashield");
    var myHeaders = new Headers();
    var myHeadersPayment = new Headers();

    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      quantity: qty,
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    var requestOptionsDelete = {
      method: "DELETE",
      headers: myHeadersPayment,
      redirect: "follow",
    };
    if (addedCart?.cart?.payment_sessions.length !== 0) {
      const checkPaymentSessionBindOrNot = await fetch(
        `${process.env.REACT_APP_API_URL}/store/carts/${cart_id}/payment-sessions/${addedCart?.cart?.payment_sessions[0].provider_id}`,
        requestOptionsDelete
      );
      // console.log(checkPaymentSessionBindOrNot.status, checkPaymentSessionBindOrNot.ok)
    }
    await fetch(
      `${process.env.REACT_APP_API_URL}/store/carts/${cart_id}/line-items/${lineId}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setAddedCart(result);
        setLocalShipping(0);
        // Adddiscount()
      })
      .catch((error) => console.log("error", error));
  };
  const DeleteQuantity = async (item_id) => {
    const cart_id = localStorage.getItem("cartid_instashield");
    var myHeaders = new Headers();
    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };
    var myHeadersPayment = new Headers();

    var requestOptionsDelete = {
      method: "DELETE",
      headers: myHeadersPayment,
      redirect: "follow",
    };
    if (addedCart?.cart?.payment_sessions.length !== 0) {
      const checkPaymentSessionBindOrNot = await fetch(
        `${process.env.REACT_APP_API_URL}/store/carts/${cart_id}/payment-sessions/${addedCart?.cart?.payment_sessions[0].provider_id}`,
        requestOptionsDelete
      );
    }
    await fetch(
      `${process.env.REACT_APP_API_URL}/store/carts/${cart_id}/line-items/${item_id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setAddedCart(result);
        notify("Product Removed Successfully!");
        setLocalShipping(0)
      })
      .catch((error) => console.log("error", error));
  };
  const CreateNewCartId = async (PreviousArray, oldRegionId) => {
    var raw = JSON.stringify({
      region_id: oldRegionId,
      items: PreviousArray,
    });
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    await fetch(
      `${process.env.NEXT_PUBLIC_API_URL}/store/carts`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result) {
          if (result.cart.id) {
            setAddedCart(result);
            localStorage.setItem("cartid_instashield", result.cart.id);
          }
        }
      })
      .catch((error) => console.log("error", error));
  };
  const couponApplied = async (code) => {
    const cartid = localStorage.getItem("cartid_instashield");
    var myHeadersPayment = new Headers();

    var requestOptionsDelete = {
      method: "DELETE",
      headers: myHeadersPayment,
      redirect: "follow",
    };
    if (addedCart?.cart?.payment_sessions.length !== 0) {
      const checkPaymentSessionBindOrNot = await fetch(
        `${process.env.NEXT_PUBLIC_API_URL}/store/carts/${cartid}/payment-sessions/${addedCart?.cart?.payment_sessions[0].provider_id}`,
        requestOptionsDelete
      );
      // console.log(checkPaymentSessionBindOrNot.status, checkPaymentSessionBindOrNot.ok)
    }
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      discounts: [
        {
          code: code,
        },
      ],
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${process.env.NEXT_PUBLIC_API_URL}/store/carts/${cartid}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.type === "not_found") {
          setCoupenResponse(result);
        }
        if (result.cart.id) {
          setAddedCart(result);
        }
      })
      .catch((error) => console.log("error", error));
  };
  const hellow = () => {
    window.open("/thankyou");
  };
  const checkoutHandler = async (order_id) => {
    
    setLoadingPayment(false)
    const cart_id = localStorage.getItem("cartid_instashield");
    const data = process.env.REACT_APP_NEXT_PUBLIC_RAZORPAY_KEY;
    const options = {
      data,
      currency: "INR",
      name: "INSTASHIELD ",
      // callback_url: 'http://localhost:3002/thankyou',
      description: "Testing in INSTASHIELD",
      image:
        "https://instashield-ecommerce-media.s3.ap-south-1.amazonaws.com/LOGO.png",
      order_id: order_id,
      redirect: false,
      handler: function (response) {
        if (
          response.razorpay_payment_id &&
          response.razorpay_order_id &&
          response.razorpay_signature
        ) {
          navigate("/thankyou");
        } else {
          alert(response.razorpay_payment_id);
          alert(response.razorpay_order_id);
          alert(response.razorpay_signature);
        }
      },

      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#121212",
      },
    };
    // const redirectUrl = "/thankyou"; // Modify the URL as needed
    // router.push(redirectUrl);

    var rzp1 = new window.Razorpay(options);
    
    rzp1.open();
    // razor.on('payment')
  };
  const Paynow = async () => {
    setLoadingPayment(true);
    const cart_id = localStorage.getItem("cartid_instashield");
    var myHeadersPayment = new Headers();
    var myHeaders = new Headers();
    var myHeaderAssociate = new Headers();
    myHeaderAssociate.append("Content-Type", "application/json");
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      shipping_address: {
        first_name: createAddress.first_name,
        last_name: createAddress.last_name,
        address_1: createAddress.address_1,
        address_2: createAddress.address_1,
        city: createAddress.city,
        country_code: createAddress.country_code,
        postal_code: createAddress.postal_code,
        phone: createAddress.phone,
      },
    });

    var rawCompleteCart = JSON.stringify({
      email: createAddress.email,
    });

    var rawCompleteSession = JSON.stringify({
      provider_id: "razorpay-custom",
    });
    var copyCart = JSON.stringify({
      cart_id: cart_id,
    });
    var copyCartRequestOption = {
      method: "POST",
      headers: myHeaders,
      body: copyCart,
      redirect: "follow",
    };
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    var requestOption = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
      body: rawCompleteSession,
    };
    var requestOptionsCompleteCart = {
      method: "POST",
      headers: myHeaderAssociate,
      body: rawCompleteCart,
      redirect: "follow",
    };

    var requestOptionsDelete = {
      method: "DELETE",
      headers: myHeadersPayment,
      redirect: "follow",
    };
    var rawBindShipping = JSON.stringify({
      option_id: localShipping.id,
    });
    var requestOptionsBind = {
      method: "POST",
      headers: myHeaders,
      body: rawBindShipping,
      redirect: "follow",
    };

    try {
      // if (addedCart?.cart?.shipping_methods.length !== 0) {
      //   const copyCart = await fetch(`${process.env.NEXT_PUBLIC_API_URL}/store/cart/copycart`, copyCartRequestOption)
      //     .then((response) => response.json())
      //     .then((result) => {
      //       if (result.oldItems.length !== 0) {
      //         return result
      //       }
      //     })
      //   await CreateNewCartId(copyCart.oldItems, copyCart.oldRegionId)
      //   // if (copyCart.discounts) {
      //   //   couponApplied(copyCart.discounts[0].code)
      //   // }
      // }
      if (addedCart?.cart?.payment_sessions.length !== 0) {
        const new_cart_id = localStorage.getItem("cartid_instashield");
        const checkPaymentSessionBindOrNot = await fetch(
          `${process.env.REACT_APP_API_URL}/store/carts/${new_cart_id}/payment-sessions/${addedCart?.cart?.payment_sessions[0].provider_id}`,
          requestOptionsDelete
        );
      }
      const new_cart_id = localStorage.getItem("cartid_instashield");
      const Customebind = await fetch(
        `${process.env.REACT_APP_API_URL}/store/carts/${new_cart_id}`,
        requestOptionsCompleteCart
      );
      if (Customebind.status === 200 && Customebind.ok === true) {
        const BindShipping = await fetch(
          `${process.env.REACT_APP_API_URL}/store/carts/${new_cart_id}/shipping-methods`,
          requestOptionsBind
        );
        if ((BindShipping.status === 200, BindShipping.ok === true)) {
          const Addressbind = await fetch(
            `${process.env.REACT_APP_API_URL}/store/carts/${new_cart_id}`,
            requestOptions
          );
          if (Addressbind.status === 200 && Addressbind.ok === true) {
            await fetch(
              `${process.env.REACT_APP_API_URL}/store/carts/${new_cart_id}/payment-sessions`,
              requestOption
            )
              .then((response) => response.json())
              .then((result) => {
                if (
                  result?.data?.payment_sessions[0]?.id &&
                  result?.data?.payment_sessions[0]?.data?.id
                ) {
                  checkoutHandler(result?.data?.payment_sessions[0]?.data?.id);
                  setAddedCart(result);
                } else {
                  setLoadingPayment(false);
                  notify('Something Went Wrong!')
                }
              })
              .catch((error) => {
                if (error) {
                  setLoadingPayment(false);
                  notify('Something Went Wrong!')
                }
              });
          } else {
            setLoadingPayment(false);
            notify('Something Went Wrong!')
          }
        } else {
          setLoadingPayment(false);
          notify("Something Went Wrong!");
        }
      } else {
        setLoadingPayment(false);
        notify("Something Went Wrong!");
      }
    } catch (err) {
      console.log(err);
    }
  };
  //all location function
  const ApplyGST = (order_id) => {
    axios.post(`${process.env.REACT_APP_API_URL}/store/gst`, {
      "gst_number": gst, "order_id": order_id
    })
      .then(function (response) {
       notify('GST Applied');
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  const getUserLocation = (variant_id, qty) => {
    setLoadingProduct(true)
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
          setLatitude(latitude);
          setLongitude(longitude);
          if (latitude && longitude) {
            const value = await getLocationFromCoordinates(latitude, longitude);
            const country_name =
              value[0]?.formatted_address?.split(" ")[
              value[0]?.formatted_address?.split(" ").length - 1
              ];
            value[0]?.address_components?.filter(async (item, i) => {
              if (item.long_name === country_name) {
                const isoName = item.short_name.toLowerCase();
                const response = await axios.get(
                  `${process.env.REACT_APP_API_URL}/store/regions`
                );
                const regions = response.data.regions;
                regions.map((item, i) => {
                  item.countries.map((item, i) => {
                    if (item.iso_2 === isoName) {
                      AddToCart(variant_id, qty, item.region_id);

                    }
                  });
                });
              }
            });
          }
        },
        async (error) => {
          if (error) {
            const response = await axios.get(
              `${process.env.REACT_APP_API_URL}/store/regions`
            );
            const regions = response.data.regions;
            regions.map((item, i) => {
              item.countries.map((item, i) => {
                if (item.iso_2 === "in") {
                  AddToCart(variant_id, qty, item.region_id);

                }
              });
            });
          }
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  const getLocationFromCoordinates = async (latitude, longitude) => {
    try {
      const apiKey = `${process.env.REACT_APP_GOOGLE_API_KEY}`; // Replace with your own Google Maps API key
      const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`;
      const response = await axios.get(apiUrl);
      if ((response.status === 200, response.data.status === "OK")) {
        const location = response.data.results;
        return location;
      } else {
        return "Location not found";
      }
    } catch (error) {
      return "Error fetching location";
    }
  };

  return (
    <Auth.Provider
      value={{
        getUserLocation,
        Paynow,
        DeleteQuantity,
        getProduct,
        setAddedCart,
        productData,
        setProductData,
        AddedProduct,
        AddToCart,
        addedCart,
        UpdateQuantity,
        createAddress,
        setCreateAddress,
        getStateAndCity,
        notifyError,
        errorMessageForPincode,
        setErrorMessageForPincode,
        notify,
        getLocationFromCoordinates,
        longitude,
        latitude,
        getUserLocationFailed,
        localShipping,
        setLocalShipping,
        getShippingOption,
        loadingProduct,
        setLoadingProduct,
        loadingPayment,
        setLoadingPayment,
        ApplyGST,
        gst, setGst,
        gstValidated, setGstValidated
      }}
    >
      {children}
    </Auth.Provider>
  );
}

export default Authcontext;
