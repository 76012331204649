import React from "react";
import "./footer.css";
import { Link } from "react-router-dom";

const Footer = () => {
  const handleClick = (e) => {
    if (document.documentElement.scrollTop !== 0 || document.body.scrollTop !== 0) {
      if ('scrollBehavior' in document.documentElement.style) {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      } else {
        document.documentElement.scrollTop = 0;
        document.body.scrollTop = 0;
      }
    }

  };

  const items1 = [
    { title: "Home", link: "/" },
    { title: "Product", link: "/product" },
    { title: "Technology", link: "/technology" },
    { title: "Blogs", link: "/blog" },
    { title: "Certifications", link: "/certifications" },
    { title: "Dealership Enquiry", link: "/enquiry" },
  ];

  const items2 = [
    { title: "About", link: "/about" },
    { title: "FAQs", link: "/faq" },
    { title: "Contact Us", link: "/contact" },
    { title: "Cancellation & Refund", link: "/refund" },
  ];


  return (
    <div>
      <div className="bg1 d-flex flex-column justify-content-center align-items-center container-fluid text-white p-lg-5 p-1  ">
        <Link to="/" onClick={handleClick}><img src="/images/footer_logo.png" alt="logo" className="img mt-lg-0 mt-3" /></Link>
        <img
          src="/images/health.png"
          alt="Health"
          className="p-4 health img-fluid mt-lg-2 mt-2 health_img"
        />
       
        <div className="d-flex gap-lg-4 gap-2 gap-md-3  text1 p-lg-3 p-0  ">
          {items1.map((item,index) => (
            <Link to={item.link} className="navitems d-lg-block d-md-block d-none" onClick={handleClick} key={index}>
              <p>{item.title}</p>

            </Link>
          ))}
        </div>
        <div className="d-flex gap-lg-4 gap-2 text2 p-1 ">
        {items2.map((item,index) => (
            <a href={item.link} className="navitems  d-lg-block d-md-block d-none"  key={index}>
              <p>{item.title}</p> 

            </a>
          ))}
          <Link
            target="_blank"
            className="text2 text-decoration-none navitems d-lg-block d-md-block d-none"
            to="https://instashield-ecommerce-media.s3.ap-south-1.amazonaws.com/INSTASHIELD+BROCHURE+2023.pdf"
          >
            Download Brochure
          </Link>
        </div>


<div className="text-center d-lg-none d-md-none d-block">
        <div className="col-12  p-2  ">
          {items1.map((item,index) => (
            <Link to={item.link} className="navitems_mobile " onClick={handleClick} key={index}>
              <p>{item.title}</p>

            </Link>
          ))}
        </div>
        <div className="col-12  p-2 ">
          {items2.map((item,index) => (
            <Link to={item.link} className="navitems_mobile " onClick={handleClick} key={index}>
              <p>{item.title}</p>

            </Link>
          ))}
          <Link
            target="_blank"
            className=" text-decoration-none navitems_mobile d-lg-none d-md-none d-block "
            to="https://instashield-ecommerce-media.s3.ap-south-1.amazonaws.com/INSTASHIELD+BROCHURE+2023.pdf"
          >
            Download Brochure
          </Link>
        </div>

        </div>


        
        <div className="p-2 mt-lg-3 mt-3 d-flex flex-column text-center fot ln-sm">
          <p className="insta p-0 m-0">INSTASHIELD INDIA PVT LTD.</p>
          <p className="footer_address mt-lg-0 mt-2 d-lg-block d-md-block d-none">
            Address: 904, Meraki Arena, V. N. Purav Marg, Opp R. K. Studio,
            Above Croma Showroom, <br /> Chembur (East), Mumbai - 400 071.
      
          <span className="m-0 py-1  "> Email <a href = "mailto: info@instashield.org" className="navitems   text-decoration-none ">info@instashield.org</a> </span>
          </p>
          <p className="footer_address mt-lg-0 mt-2 d-lg-none d-md-none d-block">
            Address: 904, Meraki Arena, V. N. Purav Marg, Opp R. K. Studio,<br />
            Above Croma Showroom,  Chembur (East), Mumbai - 400 071.
      
            <br /> <span className="m-0 py-1  "> Email <a href = "mailto: info@instashield.org" className="navitems   text-decoration-none ">info@instashield.org</a> </span>
          </p>
          
        </div>
        <p className="toll m-0  "> Toll Free No. : <a className="navitems" href="tel: 18002028308" > 18002028308 </a></p>
        {/* <p className="text-center scan">
          or scan the QR code <br /> To know about this breakthrough technology,
        </p>
        <img src="/images/qr.png" alt="qr" className="qrimg" /> */}
        <Link className="navitems p-3 fot " to="/terms" onClick={handleClick}>
          {" "}
          T&C PRIVACY POLICY
        </Link>
        <div className="d-flex gap-lg-5 gap-5 icons mt-lg-3 mb-lg-0 mb-4 ">
          <Link to="https://www.facebook.com/instashield.ind/" target="_blank">
            <img src="/icons/fb.svg" alt="fb" />
          </Link>
          <Link
            to="https://www.instagram.com/instashield.ind/ "
            target="_blank"
          >
            {" "}
            <img src="/icons/insta.svg" alt="insta" />
          </Link>
          <Link
            to="https://www.youtube.com/channel/UCndtsfR9noPlHXukFY_OzeQ "
            target="_blank"
          >
            {" "}
            <img src="/icons/youtube.svg" alt="youtube" />
          </Link>
          <Link to="https://twitter.com/instashield_ind " target="_blank">
            {" "}
            <img src="/icons/twit.svg" alt="twit" />
          </Link>
          <Link
            to="https://www.linkedin.com/company/instashield-ind/ "
            target="_blank"
          >
            {" "}
            <img src="/icons/linkedin.svg" alt="linkedin" />
          </Link>
        </div>
      </div>
      <div className=" container-fluid text-center text2 p-3 last d-lg-block d-md-block d-none">
        <p className="m-0 fontsize13">
          © 2023 INSTASHIELD MADE WITH{" "}
          <i className="fa-solid fa-heart text_purple"></i> BY
          <Link
            target="_blank"
            className="footer_weblink "
            to="https://leadsbrandconnect.com/"
          >
            <span className="text-bold mx-1 fontsize13 text2 footer_weblink">
              LEADS BRAND CONNECT
            </span>
          </Link>
        </p>
      </div>
      <div className=" container-fluid text-center text2 p-3 last d-lg-none d-md-none d-block">
        <p className="m-0 fontsize13">
          © 2023 INSTASHIELD MADE WITH{" "}
          <br></br> <i className="fa-solid fa-heart text_purple"></i> BY
          <Link
            target="_blank"
            className="footer_weblink "
            to="https://leadsbrandconnect.com/"
          >
            <span className="text-bold mx-1 fontsize13 text2 footer_weblink">
              LEADS BRAND CONNECT
            </span>
          </Link>
        </p>
      </div>
    </div>
  );
};

export default Footer;
