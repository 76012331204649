import React, { useContext, useEffect, useState } from 'react';
import { Auth } from './AuthContext/Authcontext';
function Test() {
    const { getLocationFromCoordinates }=useContext(Auth)  
    const [latitude, setLatitude] = useState("")
    const [longitude, setLongitude] = useState("")
    const getUserLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    setLatitude( latitude );
                    setLongitude( longitude );
                },
                (error) => {
                    console.error('Error getting user location:', error);
                }
            );
        }
        else {
            console.error('Geolocation is not supported by this browser.');
        }
    };
    useEffect(() => {
        // Fetch location when component mounts
        const fetchLocation = async () => {
            const result = await getLocationFromCoordinates(latitude, longitude);
            console.log(result);
        };
        fetchLocation();
    }, [latitude, longitude]);
    // return an HTML page for the user to check their location
    // useEffect(() => {
    //     const fetchLocation = async () => {
    //         try {
    //             const response = await fetch(
    //                 `https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json`
    //             );

    //             if (response.ok) {
    //                 const data = await response.json();
    //                 const address = data.display_name; // Adjust as per the required address format
    //                 setLocation(address);
    //             } else {
    //                 setLocation('Location not found');
    //             }
    //         } catch (error) {
    //             console.error('Error fetching location:', error);
    //             setLocation('Error fetching location');
    //         }
    //     };

    //     fetchLocation();
    // }, [latitude, longitude]);
    return (
        <div>
            <h1>Geolocation App</h1>
            <button onClick={getUserLocation}>Get User Location</button>
            {latitude && longitude &&  (
                <div>
                    <h2>User Location</h2>
                    <p>Latitude: {latitude}</p>
                    <p>Longitude: {longitude}</p>
                </div>
            )}
        </div>
    );
}

export default Test;